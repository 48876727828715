<template>
  <v-app class="layout-bg">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "public",
};
</script>

<style lang="scss" scoped>
.layout-bg {
  background: #f4fcff;
}
</style>
